import React from 'react'
import BuildingForAptCollector from '../../Component/BuildingForAptCollector/BuildingForAptCollector'
import { Link } from 'react-router-dom'
const AptCollector = () => {
  return (
    <>
        <BuildingForAptCollector></BuildingForAptCollector>
    </>  
    )
}

export default AptCollector